import Vue from 'vue';
import VueI18n, { Locale, LocaleMessages } from 'vue-i18n';
import axios from 'axios';
import store from './store';
import cachedAxios from '@/core/CachedAxios';
import { VehicleType } from '@/core/interfaces/vehicleType';

Vue.use(VueI18n);

let vehicleTypeMessagesLoaded = false;

const loadLocaleMessagesFromLocal = (): LocaleMessages => {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            const messagesForLocale = locales(key);

            // eslint-disable-next-line no-prototype-builtins
            messages[locale] = messagesForLocale.hasOwnProperty('default')
                ? messagesForLocale.default
                : messagesForLocale;
        }
    });

    return messages;
};

const locale = (window.localStorage.getItem('lely_alarmcenter_locale') == null)
    ? (process.env.VUE_APP_I18N_LOCALE || 'en')
    : window.localStorage.getItem('lely_alarmcenter_locale');

const i18n = new VueI18n({
    locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessagesFromLocal(),
});

const loadLocaleMessagesFromRemote = async() => {
    const response = await axios.get(`${process.env.VUE_APP_TRANSLATIONS_URL}/alarmcenter/locales`);

    const locales = response.data.reduce((acc: Record<string, string>, locale: { code: string; name: string }) => {
        acc[locale.code] = locale.name;

        return acc;
    }, {});

    store.commit('setLocales', locales);

    const promisses = response.data.map(async(locale: { code: string }) => {
        const messagesResponse = await axios.get(`${process.env.VUE_APP_TRANSLATIONS_URL}/alarmcenter/locales/${locale.code}`);
        i18n.mergeLocaleMessage(locale.code, messagesResponse.data);
    });

    await Promise.all(promisses);
};

export const loadLocaleForVehiclesTypes = async(locales: string[], vehicleTypes: VehicleType[]): Promise<void> => {
    if (vehicleTypeMessagesLoaded) {
        return;
    }

    const loadVehiclePhases = async(vehicleType: VehicleType, locale: Locale) => {
        const url = `/vehicle-types/${vehicleType.id}/locales/${locale}/vehicle-phases`;
        const data = (await cachedAxios.get(url)).data;

        i18n.mergeLocaleMessage(locale, {
            [vehicleType.name]: {
                'vehicle-phases': data,
            },
        });
    };

    const loadNotificationCodes = async(vehicleType: VehicleType, locale: Locale) => {
        const url = `/vehicle-types/${vehicleType.id}/locales/${locale}/notification-types`;
        const data = (await cachedAxios.get(url)).data;

        i18n.mergeLocaleMessage(locale, {
            [vehicleType.name]: {
                'notification-types': data,
            },
        });
    };

    const loadAppTranslations = async(vehicleType: VehicleType, locale: Locale) => {
        const url = `/vehicle-types/${vehicleType.id}/locales/${locale}/app`;
        const data = (await cachedAxios.get(url)).data;

        i18n.mergeLocaleMessage(locale, {
            [vehicleType.name]: {
                app: data,
            },
        });
    };

    vehicleTypeMessagesLoaded = true;

    await Promise.all(
        vehicleTypes.flatMap(vehicleTypes =>
            locales.flatMap(locale => [
                loadVehiclePhases(vehicleTypes, locale),
                loadNotificationCodes(vehicleTypes, locale),
                loadAppTranslations(vehicleTypes, locale),
            ])));
};

loadLocaleMessagesFromRemote().then();

export default i18n;
