











































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import UsesTicket from '@/components/mixins/UsesTicket';
import { Getter } from 'vuex-class';
import { TicketCategory } from '@/core/interfaces/ticketCategory';
import CategoryLabel from '@/components/tickets/CategoryLabel.vue';
import FormatsDate from '@/components/mixins/FormatsDate';
import { Ticket } from '@/core/interfaces/ticket';
import { formatISO } from 'date-fns';
import { User } from '@/core/interfaces/user';
import { Vehicle } from '@/core/interfaces/vehicle';
import OpensDashboardModal from '@/components/mixins/OpensDashboardModal';
import KnowledgeBaseLink from '@/components/KnowledgeBaseLink.vue';
import { VehicleType } from '@/core/interfaces/vehicleType';
import vSelect from 'vue-select';

@Component({
    components: { vSelect, KnowledgeBaseLink, CategoryLabel },
})
export default class TicketSidebar extends mixins(UsesTicket, FormatsDate, OpensDashboardModal) {
    @Getter('tickets/categories')
    categories!: TicketCategory[];

    @Getter('usersSet')
    users!: User[];

    @Getter('vehiclesByFarm')
    vehiclesByFarm!: (id: number) => Vehicle;

    editCategory = false;
    editSubject = false;
    editVehicle = false;

    notes = '';

    subject = '';

    loading = false;

    assigned: number | null = null;
    categoryId: number | null = null;
    vehicleId: number | null = null;

    @Watch('ticket.id', { immediate: true })
    setForm(): void {
        this.editCategory = false;
        this.editSubject = false;
        this.editVehicle = false;

        this.notes = this.ticket.notes;
        this.subject = this.ticket.subject;
        this.assigned = this.ticket.assigned_to;
        this.categoryId = this.ticket.ticket_category_id;
        this.vehicleId = this.ticket.vehicle_id;
    }

    async close(): Promise<void> {
        this.loading = true;
        await this.$store.dispatch('tickets/updateTicket', {
            id: this.ticket.id,
            closed_by: this.$store.getters.currentUser.id,
            closed_at: formatISO(new Date()),
        } as Partial<Ticket>);
        this.loading = false;
    }

    async reopen(): Promise<void> {
        this.loading = true;
        await this.$store.dispatch('tickets/updateTicket', {
            id: this.ticket.id,
            closed_by: null,
            closed_at: null,
        } as Partial<Ticket>);
        this.loading = false;
    }

    async save(): Promise<void> {
        this.loading = true;
        const ticket = await this.$store.dispatch('tickets/updateTicket', {
            id: this.ticket.id,
            notes: this.notes,
            subject: this.subject,
            assigned_to: this.assigned,
            ticket_category_id: this.categoryId,
            vehicle_id: this.vehicleId,
        } as Partial<Ticket>);

        this.loading = false;
        this.editCategory = false;
        this.editSubject = false;
        this.editVehicle = false;

        if (this.$route.query.code !== ticket.code) {
            await this.$router.push({ name: this.$route.name || 'tickets.assigned', query: { code: ticket.code } });
        }
    }

    closeModalAndOpenDashboardModal() {
        if (!this.vehicle) {
            return;
        }

        this.$bvModal.hide('ticket-modal');
        this.openDashboardModal(this.vehicle);
    }

    reset(): void {
        this.setForm();
    }

    get vehicleType(): VehicleType | null {
        if (!this.vehicle) {
            return null;
        }

        return this.$store.getters.vehicleTypeById(this.vehicle.vehicle_type_id);
    }
}

