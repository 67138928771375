



























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Loader from '@/components/Loader.vue';
import { DashboardDefinition } from '@/core/interfaces/dashboardDefinition';
import DropdownMenu from '@innologica/vue-dropdown-menu';

const vehicleDashboard = namespace('vehicleDashboard');

@Component({
    components: { Loader, DropdownMenu },
})
export default class DashboardStatus extends Vue {
    @vehicleDashboard.State('status')
    private status!: string;

    @vehicleDashboard.State('statusTopic')
    private statusTopic!: string;

    @vehicleDashboard.State('definition')
    private definition!: DashboardDefinition;

    private statusTopicShow = false;

    get selectedStatusLabel(): string | null {
        const definition = this.$store.state.vehicleDashboard.definition as DashboardDefinition;
        if (!definition) {
            return null;
        }

        const status = definition.statusses.find(status => status.topic === this.statusTopic);

        if (!status) {
            return null;
        }

        return status.label;
    }

    setStatusTopic(topic: string): void {
        this.statusTopicShow = false;
        this.$store.dispatch('vehicleDashboard/loadStatus', topic);
    }

    get statusTranslated(): string {
        return this.status
            .replace(/<span data-translate>(.*?)<\/span>/g, (match, key) => this.$t(key).toString());
    }
}

