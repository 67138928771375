








import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Notification } from '@/core/interfaces/notification';
import axios from 'axios';
import PhoneLog from '@/core/interfaces/phoneLog';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { Getter } from 'vuex-class';
import { Phone } from '@/core/interfaces/phone';
import { User } from '@/core/interfaces/user';
import FormatsDate from '@/components/mixins/FormatsDate';
import { VNode } from 'vue';

@Component({
    components: {},
})
export default class NotificationPhoneLog extends mixins(FormatsDate) {
    @Prop({ required: true })
    protected notification!: Notification;

    @Getter('phoneById')
    phoneById!: (id: number) => Phone;

    @Getter('userById')
    userById!: (id: number) => User;

    phoneLog: PhoneLog[] | null = null;

    get columns(): LelyTableColumnsConfig<PhoneLog> {
        return [
            {
                header: 'Recipient',
                value: log => {
                    const phone = this.phoneById(log.phone_id);
                    const user = this.userById(phone.user_id);

                    return user.username;
                },
                sub: log => {
                    const phone = this.phoneById(log.phone_id);

                    return phone.device_info;
                },
            },
            {
                header: 'Send',
                value: log => log.send_successful,
                type: 'check',
                sub: log => this.createSub(log.send_at, log.send_status),
            },
            {
                header: 'Called',
                value: log => log.called_successful,
                type: 'check',
                sub: log => this.createSub(log.called_at, log.called_status),
            },
            {
                header: 'Alarmer',
                type: 'check',
                value: log => !!log.alarmer_ack_at,
                sub: log => this.createSub(log.alarmer_ack_at, log.alarmer_phone_number_used),
            },
            {
                header: 'Cleared',
                value: log => log.cleared_successful,
                type: 'check',
                sub: log => this.createSub(log.cleared_at, log.cleared_status),
            },
            {
                header: 'Disables',
                value: log => this.getDisables(log),
            },
        ];
    }

    private createSub(clearedAt: string | null, status: string | null): string {
        const parts = [];

        if (clearedAt) {
            parts.push(this.formatDate(clearedAt));
        }

        if (status) {
            parts.push(status);
        }

        return parts.join(' / ');
    }

    private getDisables(log: PhoneLog): VNode {
        const items: VNode[] = [];

        if (log.incoming_call_disabled_at) {
            items.push(this.$createElement('li', `incoming call (${this.formatDate(log.incoming_call_disabled_at)})`));
        }

        if (log.push_notification_disabled_at) {
            items.push(this.$createElement('li', `push (${this.formatDate(log.push_notification_disabled_at)})`));
        }

        if (log.operator_call_disabled_at) {
            items.push(this.$createElement('li', `operator call (${this.formatDate(log.operator_call_disabled_at)})`));
        }

        return this.$createElement('ul', {
            class: 'pl-3',
        }, items);
    }

    async mounted() {
        const response = await axios.get<PhoneLog[]>(`/notifications/${this.notification.id}/notify_logs`);
        this.phoneLog = response.data;
    }
}
