import { RootState } from '@/store/rootState';
import RunViewPreference from '@/core/enums/runViewPreference';
import NotificationLevel from '@/core/enums/notificationLevel';

export default {
    notificationTypes: {},
    notificationTypesList: [],
    farms: {},
    farmsList: [],
    farmVehicleTypes: [],
    users: {},
    usersList: [],
    userRoles: [],
    roles: {},
    rolesList: [],
    vehicles: {},
    vehiclesList: [],
    vehiclePhases: {},
    vehiclePhasesList: [],
    vehicleTypes: {},
    vehicleTypesList: [],
    currentUserId: null,
    locales: {},
    runViewPreference: RunViewPreference.EXPANDED,
    visibleVehicleTypeIds: [],
    visibleNotificationLevels: [NotificationLevel.WARNING, NotificationLevel.ERROR],
    groupRuns: false,
    phones: [],
    alarmerStatus: null,
} as RootState;
