import { Component, Vue } from 'vue-property-decorator';
import { VehiclePhase } from '@/core/interfaces/vehiclePhase';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component
export default class TranslatesVehiclePhase extends Vue {
    public translateVehiclePhase(vehiclePhase: VehiclePhase): string {
        const vehicleType = this.$store.getters.vehicleTypeById(vehiclePhase.vehicle_type_id) as VehicleType;

        return this.$t(`${vehicleType.name}.vehicle-phases.${vehiclePhase.code_name}`).toString();
    }
}
