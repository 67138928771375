






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { NotificationType } from '@/core/interfaces/notificationType';
import { VehicleType } from '@/core/interfaces/vehicleType';
import KnowledgeBaseNavItem from '@/components/knowledge-base/KnowledgeBaseNavItem.vue';
import TopnavLayout from '@/components/views/layouts/TopnavLayout.vue';
import { Farm } from '@/core/interfaces/farm';
import { KnowledgeBaseSection } from '@/core/interfaces/knowledgeBaseSection';
import KnowledgeBaseManageSectionsModal from '@/components/knowledge-base/KnowledgeBaseManageSectionsModal.vue';

@Component({
    components: { KnowledgeBaseManageSectionsModal, TopnavLayout, KnowledgeBaseNavItem },
})
export default class KnowledgeBaseNav extends Vue {
    @Prop({ required: true })
    vehicleTypeName!: string;

    @Prop({ default: '' })
    notificationTypeCode!: string;

    @Prop({ default: '' })
    farmId!: string;

    @Prop({ default: '' })
    sectionId!: string;

    @Getter('vehicleTypeByName')
    vehicleTypeByName!: (name: string) => VehicleType;

    @Getter('knowledgeBase/sections')
    sections!: KnowledgeBaseSection[];

    @Getter('notificationTypesByVehicleTypeId')
    notificationTypesByVehicleTypeId!: (vehicleTypeId: number) => NotificationType[];

    searchQuery = '';

    mounted(): void {
        this.scrollToActive();
    }

    get notificationTypes(): NotificationType[] {
        const searchQueryLower = this.searchQuery.toLowerCase();

        return this.notificationTypesByVehicleTypeId(this.vehicleType.id)
            .filter(type => {
                return this.searchQuery === '' ||
                    String(type.code)
                        .toLowerCase()
                        .includes(searchQueryLower) ||
                    this.$i18n.t(`${this.vehicleType.name}.notification-types.${type.code}`)
                        .toString()
                        .toLowerCase()
                        .includes(searchQueryLower);
            });
    }

    get vehicleType(): VehicleType {
        return this.vehicleTypeByName(this.vehicleTypeName);
    }

    get farms(): Farm[] {
        return this.$store.getters.farmsByVehicleType(this.vehicleType.id);
    }

    @Watch('searchQuery')
    listUpdate(): void {
        (this.$refs.list as HTMLElement).scrollTop = 0;
    }

    scrollToActive(): void {
        const active = (this.$refs.list as HTMLElement).querySelector('.active');

        if (active) {
            active.scrollTo();
        }
    }
}

