







import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import UsesTicket from '@/components/mixins/UsesTicket';
import TicketConversation from '@/components/tickets/TicketConversation.vue';
import TicketSidebar from '@/components/tickets/TicketSidebar.vue';

@Component({
    components: { TicketConversation, TicketSidebar },
})
export default class TicketDetails extends mixins(UsesTicket) {
    mounted(): void {
        this.$store.commit('tickets/setTicketSeen', this.ticket);
    }

    back(): void {
        this.$emit('close');
    }
}

