


































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import LocaleSelect from '@/components/LocaleSelect.vue';
import { Getter } from 'vuex-class';
import { LelyApp, LelyAuth } from '@tec/frontend-vue-shared';
import FormatsDate from '@/components/mixins/FormatsDate';
import { format } from 'date-fns';

@Component({
    methods: { format },
    components: {
        LocaleSelect,
    },
})
export default class NavigationTop extends Mixins(FormatsDate) {
    @Getter('isAdmin')
    isAdmin!: boolean;

    get loginPage() {
        return this.$route.meta?.layout === 'LoginLayout';
    }

    get app(): LelyApp {
        return LelyApp.ALARMCENTER;
    }

    get alarmerStatus(): { updated_at: string; alive: boolean } | null {
        return this.$store.state.alarmerStatus;
    }

    logout() {
        this.$store.dispatch('stopSyncInterval');
        LelyAuth.logout();

        this.$router.push({ name: 'login' });
    }
}

