









































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarLayout from '@/components/views/layouts/SidebarLayout.vue';
import AddTicketModal from '@/components/tickets/AddTicketModal.vue';
import TicketCard from '@/components/tickets/TicketCard.vue';
import { Ticket } from '@/core/interfaces/ticket';
import TicketDetails from '@/components/tickets/TicketDetails.vue';
import { Getter } from 'vuex-class';
import VisibleVehicleTypesFilter from '@/components/VisibleVehicleTypesFilter.vue';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import CategoryLabel from '@/components/tickets/CategoryLabel.vue';
import { format, parseISO } from 'date-fns';
import NewIndicator from '@/components/NewIndicator.vue';

@Component({
    components: { VisibleVehicleTypesFilter, TicketDetails, TicketCard, AddTicketModal, SidebarLayout },
})
export default class TicketsView extends Vue {
    @Prop({ required: true })
    view!: 'unassigned' | 'assigned';

    @Getter('tickets/openTickets')
    openTickets!: Ticket[];

    @Getter('tickets/assignedToMe')
    ticketsAssignedToMe!: Ticket[];

    @Getter('tickets/assignedToOthers')
    ticketsAssignedToOthers!: Ticket[];

    mounted(): void {
        this.$store.dispatch('tickets/syncAll');
    }

    get columns(): LelyTableColumnsConfig<Ticket> {
        return [
            {
                header: this.$t('tickets.category').toString(),
                value: ticket => this.$createElement(CategoryLabel, {
                    props: {
                        category: this.$store.getters['tickets/categoryById'](ticket.ticket_category_id),
                    },
                }),
                shrink: true,
            },
            {
                header: this.$t('tickets.subject').toString(),
                value: ticket => {
                    const seen = this.$store.getters['tickets/isTicketSeen'](ticket.id);
                    const h = this.$createElement;

                    if (seen) {
                        return ticket.subject;
                    }

                    return h('span', {
                        class: 'd-flex align-items-center',
                    }, [
                        h(NewIndicator, {
                            class: 'mr-1',
                        }),
                        ticket.subject,
                    ]);
                },
                sub: 'code',
                options: {
                    wrap: true,
                },
            },
            {
                header: this.$t('general.created').toString(),
                value: ticket => format(parseISO(ticket.created_at), 'yyyy-MM-dd'),
                sub: ticket => ticket.created_by_user
                    ? this.$store.getters.userById(ticket.created_by_user).username
                    : ticket.created_by_vehicle ? this.$store.getters.vehicleById(ticket.created_by_vehicle).name : '',
            },
            {
                header: this.$t('general.farm').toString(),
                value: ticket => this.$store.getters.farmById(ticket.farm_id).name,
            },
            {
                header: this.$t('general.vehicle').toString(),
                value: ticket => ticket.vehicle_id ? this.$store.getters.vehicleById(ticket.vehicle_id).name : null,
            },
            {
                header: this.$t('tickets.assigned').toString(),
                value: ticket => ticket.assigned_to ? this.$store.getters.userById(ticket.assigned_to).username : null,
            },
        ];
    }

    get openedTicket(): Ticket | null {
        if (!this.$route.query.code) {
            return null;
        }

        return this.$store.getters['tickets/ticketByCode'](this.$route.query.code);
    }

    openTicket(ticket: Ticket): void {
        this.$router.push({ query: { code: ticket.code } });
    }

    @Watch('openedTicket', { immediate: true })
    scrollToOpenedTicket(newValue: Ticket, oldValue: Ticket): void {
        if (!this.openedTicket || oldValue?.id === newValue?.id) {
            return;
        }

        this.$nextTick(() => {
            // @ts-ignore
            const ticketCard = ((this.$refs.openedTicket[0] as TicketCard).$el as HTMLElement);
            const ticketCardTop = ticketCard.offsetTop;

            const overview = (this.$refs.overview as HTMLElement);
            const overviewTop = overview.scrollTop;
            const overviewBottom = (overview.scrollTop + overview.offsetHeight);

            if (ticketCardTop < overviewTop || ticketCardTop > overviewBottom) {
                ticketCard.scrollIntoView({ block: 'center' });
            }
        });
    }
}

