











































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import { RunsRequestFilters } from '@/core/interfaces/runsRequestFilters';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { Vehicle } from '@/core/interfaces/vehicle';
import { NotificationType } from '@/core/interfaces/notificationType';
import { User } from '@/core/interfaces/user';
import { endOfDay, format, parse, startOfDay } from 'date-fns';
import RunsTableViewMode from '@/core/enums/runsTableViewMode';
import { Role } from '@/core/interfaces/role';
import { mixins } from 'vue-class-component';
import TranslatesNotificationLevel from '@/components/mixins/TranslatesNotificationLevel';
import NotificationLevel, { AllNotificationLevels } from '@/core/enums/notificationLevel';
import vSelect from 'vue-select';

export type AssignResult = { type: 'role' | 'user'; id: number };

@Component({
    components: { vSelect },
})
export default class RunsTableFilters extends mixins(TranslatesNotificationLevel) {
    @Getter('farmsSet')
    farms!: Farm[];

    @Getter('vehiclesSet')
    vehicles!: Vehicle[];

    @Getter('vehicleTypesSet')
    vehicleTypes!: VehicleType[];

    @State('visibleVehicleTypeIds')
    visibleVehicleTypeIds!: number[];

    @State('visibleNotificationLevels')
    visibleNotificationLevels!: NotificationLevel[];

    @Getter('notificationTypesSet')
    notificationTypes!: NotificationType[];

    @Getter('usersSet')
    users!: User[];

    @Getter('rolesSet')
    roles!: Role[];

    @Prop({ type: String })
    view!: RunsTableViewMode;

    farmIds: number[] = [];
    vehicleIds: number[] = [];
    notificationTypeIds: number[] = [];
    runsTableViewMode = RunsTableViewMode;
    assignedTo: AssignResult | null = null;

    filters: RunsRequestFilters = {
        farmIds: null,
        vehicleTypeIds: null,
        vehicleIds: null,
        notificationTypeIds: null,
        assignedToUserIds: null,
        assignedToRoleIds: null,
        dateFrom: null,
        dateUntil: null,
        feedback: null,
        notificationLevels: this.notificationLevels,
        onlyWithSnapshot: false,
    };

    dateFrom: string | null = null;
    dateUntil: string | null = null;
    todayDate = format(new Date(), 'yyyy-MM-dd');

    mounted(): void {

    }

    get vehicleTypeIds(): number[] {
        return this.visibleVehicleTypeIds;
    }

    set vehicleTypeIds(vehicleTypeIds: number[]) {
        this.$store.commit('setVisibleVehicleTypeIds', vehicleTypeIds);
    }

    get notificationLevels(): NotificationLevel[] {
        return this.visibleNotificationLevels;
    }

    set notificationLevels(levels: NotificationLevel[]) {
        this.$store.commit('setVisibleNotificationLevels', levels);
    }

    get vehiclesFiltered(): Vehicle[] {
        let vehicles = this.vehicles;

        if (this.farmIds.length) {
            vehicles = vehicles.filter(vehicle => this.farmIds.includes(vehicle.farm_id));
        }

        if (this.vehicleTypeIds.length) {
            vehicles = vehicles.filter(vehicle => this.vehicleTypeIds.includes(vehicle.vehicle_type_id));
        }

        return vehicles.sort((a, b) => {
            const farmA = this.$store.getters.farmById(a.farm_id) as Farm;
            const farmB = this.$store.getters.farmById(b.farm_id) as Farm;

            return farmA.name.localeCompare(farmB.name);
        });
    }

    get notificationLevelOptions(): { text: string; value: NotificationLevel }[] {
        return AllNotificationLevels.map(level => ({
            text: this.translateNotificationLevel(level),
            value: level,
        }));
    }

    get notificationTypesFiltered(): NotificationType[] {
        let notificationTypes = this.notificationTypes;

        const vehicleTypeIds = [...this.vehicleTypeIds];

        if (this.vehicleIds.length) {
            vehicleTypeIds.push(...this.vehicleIds
                .map(vehicleId => this.$store.getters.vehicleById(vehicleId) as Vehicle)
                .map(vehicle => vehicle.vehicle_type_id),
            );
        }

        if (vehicleTypeIds.length) {
            notificationTypes = [...this.notificationTypes]
                .filter(notificationType => vehicleTypeIds.includes(notificationType.vehicle_type_id));
        }

        return notificationTypes.sort((typeA, typeB) => {
            const vehicleTypeA = this.$store.getters.vehicleTypeById(typeA.vehicle_type_id) as VehicleType;
            const vehicleTypeB = this.$store.getters.vehicleTypeById(typeB.vehicle_type_id) as VehicleType;

            return vehicleTypeA?.name.localeCompare(vehicleTypeB.name);
        });
    }

    reset(): void {
        this.filters = {
            farmIds: null,
            vehicleTypeIds: this.visibleVehicleTypeIds,
            vehicleIds: null,
            notificationTypeIds: null,
            assignedToUserIds: null,
            assignedToRoleIds: null,
            notificationLevels: [NotificationLevel.ERROR, NotificationLevel.WARNING],
            dateFrom: null,
            dateUntil: null,
        };

        this.dateUntil = null;
        this.dateFrom = null;
        this.assignedTo = null;

        this.vehicleIds = [];
        this.farmIds = [];
        this.notificationTypeIds = [];
        this.vehicleTypeIds = this.visibleVehicleTypeIds;
        this.notificationLevels = [NotificationLevel.ERROR, NotificationLevel.WARNING];
    }

    @Watch('farmIds')
    @Watch('vehicleTypeIds')
    resetVehicle() {
        this.vehicleIds = this.vehicleIds.filter(vehicleId => this.vehiclesFiltered.map(v => v.id).includes(vehicleId));
    }

    @Watch('vehicleIds')
    @Watch('vehicleTypeIds')
    resetNotificationType() {
        this.notificationTypeIds = this.notificationTypeIds.filter(notificationTypeId =>
            this.notificationTypesFiltered.map(nt => nt.id).includes(notificationTypeId));
    }

    @Watch('dateFrom')
    setDateFrom() {
        const date = parse(String(this.dateFrom), 'yyyy-MM-dd', new Date());
        this.filters.dateFrom = isNaN(date.getTime()) ? null : startOfDay(date);
    }

    @Watch('dateUntil')
    setDateUntil() {
        const date = parse(String(this.dateUntil), 'yyyy-MM-dd', new Date());
        this.filters.dateUntil = isNaN(date.getTime()) ? null : endOfDay(date);
    }

    @Watch('assignedTo')
    setAssignedTo() {
        this.filters.assignedToRoleIds = null;
        this.filters.assignedToUserIds = null;

        if (!this.assignedTo) {
            return;
        }

        if (this.assignedTo.type === 'user') {
            this.filters.assignedToUserIds = [this.assignedTo.id];
            return;
        }

        if (this.assignedTo.type === 'role') {
            this.filters.assignedToRoleIds = [this.assignedTo.id];
        }
    }

    @Watch('farmIds')
    setFarmIds() {
        this.filters.farmIds = this.farmIds.length ? [...this.farmIds] : null;
    }

    @Watch('vehicleIds')
    setVehicleIds() {
        this.filters.vehicleIds = this.vehicleIds.length ? [...this.vehicleIds] : null;
    }

    @Watch('vehicleTypeIds', { immediate: true })
    setVehicleTypeIds() {
        this.filters.vehicleTypeIds = this.vehicleTypeIds.length ? [...this.vehicleTypeIds] : null;
    }

    @Watch('notificationTypeIds')
    setNotificationTypeIds() {
        this.filters.notificationTypeIds = this.notificationTypeIds.length ? [...this.notificationTypeIds] : null;
    }

    @Watch('notificationLevels')
    setNotificationLevels() {
        this.filters.notificationLevels = this.notificationLevels.length ? [...this.notificationLevels] : null;
    }

    @Watch('filters', { deep: true })
    emit() {
        this.$emit('change', this.filters);
    }

    public getVehicleLabel(vehicle: Vehicle): string {
        return `${this.$store.getters.farmById(vehicle.farm_id).name} - ${vehicle.name}`;
    }

    public getNotificationTypeLabel(notificationType: NotificationType): string {
        const t = `${this.$store.getters.vehicleTypeById(notificationType.vehicle_type_id).name}.notification-types.${notificationType.code}`;

        return `${this.$store.getters.vehicleTypeById(notificationType.vehicle_type_id).name}: ${notificationType.code} - ${this.$t(t)}`;
    }

    public get feedbackItems(): {value: boolean; label: string}[] {
        return [{
            value: true,
            label: 'True',
        }, {
            value: false,
            label: 'False',
        }];
    }
}

